.Footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-content {
	position: absolute;
	width: calc(100vw - 150px * 2);
	max-width: 1250px;
	display: flex;
	justify-content: center;
	margin-bottom: 37px;
	bottom: 0;
	z-index: 10;
	padding: 0 150px;
}

.Footer .footer-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.Footer .links {
	width: 381px;
	display: flex;
	justify-content: space-between;
	word-wrap: break-word;
	align-items: center;
}

.Footer .label {
	font-weight: 600;
	font-size: 11px;
	color: #929292;
	letter-spacing: 0px;
	text-decoration: none;
}

.Footer .instagram {
	display: block;
    background-image: url(../../assets/insatgram-black.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 16px;
    /* margin: 66px 0 0 0; */
}

@media only screen and (max-width: 1200px) {
    .footer-content {
		width: calc(100vw - 100px * 2);
		padding: 0 100px;
	}
}

@media only screen and (max-width: 1060px) {
    .footer-content {
		width: calc(100vw - 60px * 2);
		padding: 0 60px;
	}
}

/* MOBILE */
@media only screen and (max-width: 935px) {
	.footer-content {
		/* width: calc(100vw - 30px * 2);
		margin-bottom: 30px;
		padding: 0 30px; */
	}

	.Footer .footer-container {
		align-items: flex-end;
	}

	.Footer .links {
		width: max-content;
		display: flex;
		justify-content: space-between;
		word-wrap: break-word;
		align-items: flex-start;
		flex-direction: column;
		gap: 10px;
	}
}

@media only screen and (max-width: 635px) {
	.footer-content {
		width: calc(100vw - 30px * 2);
		margin-bottom: 30px;
		padding: 0 30px;
	}
}