body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
	font-family: 'Montserrat', sans-serif;
	-ms-overflow-style: none;  /* IE and Edge */
  	scrollbar-width: none;  /* Firefox */
}

select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	border: none;
}

button {
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
	font-family: 'Montserrat', sans-serif;
	-webkit-tap-highlight-color: transparent;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:500,600,700,800&display=swap');