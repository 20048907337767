.Home {
    position: relative;
	padding-bottom: 120px;
}

.Home .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.Home .content-flex-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100vw;
    max-width: 1250px;
    height: 100vh;
    margin: 0 150px 0 150px;
}

.Home .split-container {
    width: calc(50vw - 150px);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.Home #app-preview-container {
    align-items: flex-end;
}

.Home .logo {
    width: 119px;
    height: 96px;
    margin: 0;
}

.Home h1 {
    font-size: 1.6em;
    letter-spacing: 6.7px;
    font-weight: 800;
    margin: 0 0 55px 0;
}

.Home .subtitle {
    font-size: 0.9em;
    opacity: 0.5;
    font-weight: 600;
    margin: 11px 0 0 0;
}

.Home .app-store-badge {
    display: block;
    background-image: url(../../assets/app-store-badge.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 136px;
    height: 45px;
    margin: 66px 0 0 0;
}

.Home .app-preview-image {
    width: 365px;
    object-fit: contain;
}

/* MOBILE */
.Home .mobile-flex-container {
    display: flex;
    align-items: center;
    width: 100vw;
    max-width: 100vw;
    flex-direction: column;
    min-height: 100vh;
}

/* DESKTOP */
@media only screen and (max-width: 1200px) {
    .Home .content-flex-container {
        margin: 0 100px 0 100px;
    }
    
    .Home .split-container {
        width: calc(50vw - 100px);
    }
}

@media only screen and (max-width: 1060px) {
    .Home .content-flex-container {
        margin: 0 50px 0 60px;
    }
    
    .Home .split-container {
        width: calc(50vw - 60px);
    }
}

/* MOBILE */
@media only screen and (max-width: 935px) {
    .Home {
        padding-bottom: 220px;
    }

    .Home .logo {
        width: 47px;
        height: 38px;
        margin: 30px 0 0 0;
    }
    
    .Home h1 {
        font-size: 1.75em;
        letter-spacing: 0.4375em;
        margin: 108px 0 34px 0;
    }

    .Home h1 span {
        letter-spacing: 0px;
    }
    
    .Home .subtitle {
        font-size: 0.77em;
        margin: 11px 0 0 0;
        width: calc(100vw - 60px);
        text-align: center;
    }
    
    .Home .app-store-badge {
        background-image: url(../../assets/app-store-badge.svg);
        width: 136px;
        height: 45px;
        margin: 45px 0 0 0;
    }
    
    .Home .app-preview-image {
        width: 306px;
        margin: 60px 0 0 0;
    }
}

@media only screen and (max-width: 665px) {
    .Home .app-preview-image {
        width: 250px;
        margin: 60px 0 0 0;
    }
}

@media only screen and (max-width: 550px) {
    .Home .app-preview-image {
        width: 206px;
        margin: 60px 0 0 0;
    }
}