.Datenschutz {
	position: relative;
}

.Datenschutz .container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Datenschutz .container .wrapper {
	padding: 136px 0 190px 0;
	min-height: 100vh;
	width: 60vw;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}

.Datenschutz .container h1 {
	font-weight: 800;
	font-size: 30px;
	letter-spacing: 0px;
	text-align: left;
}

.Datenschutz .container h2 {
	font-weight: 700;
	font-size: 25px;
	letter-spacing: 0px;
	text-align: left;
	margin-top: 50px;
}

.Datenschutz .container h3 {
	font-weight: 700;
	font-size: 20px;
	letter-spacing: 0px;
	text-align: left;
	margin-top: 15px;
	margin-bottom: 5px;
}

.Datenschutz .container p {
	font-weight: 500;
	font-size: 15px;
	letter-spacing: 0px;
	text-align: left;
	text-transform: none;
}

.Datenschutz ul {
	list-style-position: inside;
}

@media only screen and (max-width: 1600px) {
	.Datenschutz .container .wrapper  {
		width: 75vw;
	}
}

@media only screen and (max-width: 1450px) {
	.Datenschutz .container .wrapper  {
		width: 84vw;
	}
}


/* ___________ TABLET ___________ */
@media only screen and (max-width: 1100px) {
	.Datenschutz .container .wrapper  {
		min-height: 100vh;
		width: 100vw;
		padding: 140px 50px 150px 50px;
	}

	.Datenschutz .container h1 {
		font-size: 25px;
	}

	.Datenschutz .container h2 {
		font-size: 22px;
	}

	.Datenschutz .container h3 {
		font-size: 18px;
	}
}

@media only screen and (max-width: 750px) {
	.Datenschutz .container .wrapper  {
		padding: 100px 30px 150px 30px;
	}


	.Datenschutz .container h1 {
		font-size: 23px;
	}

	.Datenschutz .container h2 {
		font-size: 20px;
	}

	.Datenschutz .container h3 {
		font-size: 16px;
		margin-top: 50px;
		margin-bottom: 5px;
	}

	.Datenschutz .container p {
		font-size: 13px;
		letter-spacing: 1px;
		width: 100%;
		line-height: 20px;
	}


	.Datenschutz .container a {
		color: #3FA1EC;
		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;
	
		-ms-word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;
	
		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}

}