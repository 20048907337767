.Header {

}

.Header .content {
	display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.header-container {
	top: 0;
	position: fixed;
	z-index: 100;
	width: calc(100vw - 150px * 2);
	max-width: 1250px;
	padding: 35px 150px;
	/* background-color: white; */
}

.Header .logo {
	display: block;
    background-image: url(../../assets/namir-logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
	width: 40px;
	height: 40px;
	/* background-color: white; */
}

.Header .title-container {
	display: flex;
}


/* Bottom */
.Header .bottom-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 7px;
}

@media only screen and (max-width: 1200px) {
    .header-container {
		width: calc(100vw - 100px * 2);
		padding: 35px 100px;
	}
}

@media only screen and (max-width: 1060px) {
    .header-container {
		width: calc(100vw - 60px * 2);
		padding: 35px 60px;
	}
}

@media only screen and (max-width: 635px) {
	.header-container {
		width: calc(100vw - 30px * 2);
		padding: 30px 30px;
	}
}